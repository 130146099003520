import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Card,
  Dropdown,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import {
  optionProps,
  optionArrayProps,
} from 'components/internal/shared';
import ActionButton from 'components/molecules/ActionButton';
import BulkSelect from 'components/molecules/BulkSelect';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  removeByIndex,
  replaceAtIndex,
} from 'utilities/array';
import {
  loadTextFile,
  saveItemsCsvFile,
} from 'utilities/file';
import { post } from 'utilities/requests';
import { internalGoogleAdsAccountsPath } from 'utilities/routes';
import {
  removeAccents,
  splitLines,
} from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';
import styles from './NewGoogleAdsAccount.module.css';

const clientProps = PropTypes.objectOf(
  PropTypes.shape({
    brandId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    marketId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

const propTypes = {
  companyOptions: optionArrayProps.isRequired,
  multiBrand: PropTypes.string.isRequired,
  multiMarket: PropTypes.string.isRequired,
  brandOptions: optionArrayProps,
  clients: clientProps,
  integrationOptions: optionArrayProps,
  marketOptions: optionArrayProps,
  partnerOptions: optionArrayProps,
  selectedCompanyOption: optionProps,
  userOptions: optionArrayProps,
};

const defaultProps = {
  brandOptions: [],
  clients: [],
  integrationOptions: [],
  marketOptions: [],
  partnerOptions: [],
  selectedCompanyOption: undefined,
  userOptions: [],
};

const headers = [
  {
    alternateLabel: 'Advertiser ID',
    key: 'apiClientId',
    label: 'API Client ID',
    type: 'text',
  },
  {
    alternateLabel: 'Linked Email (integrations)',
    key: 'integration',
    label: 'Integration Email',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    alternateLabel: 'Managing Agency',
    key: 'partner',
    label: 'Partner',
  },
  {
    alternateLabel: 'Connecting User (probably you)',
    key: 'user',
    label: 'User Email',
  },
];

function createConnection() {
  return headers.reduce((obj, { key, type }) => ({
    ...obj,
    [key]: type === 'text' ? '' : null,
  }), {
    uuid: uuidv4(),
  });
}

function getDataValues(connection) {
  return headers.map(({ key }) => connection[key]);
}

function hasContent(connection) {
  return getDataValues(connection).some((value) => Boolean(value));
}

function areValid(connections, _columns, _optionsByKey) {
  if (connections.length === 0) return false;

  return connections.every((connection) => (
    getDataValues(connection).every((value) => Boolean(value))
  ));
}

function parseCsvIndices(firstLine, columns) {
  const labels = firstLine.split(',');

  return columns.map((column) => {
    const index = labels.indexOf(column.label);

    if (index === -1) {
      return labels.indexOf(column.alternateLabel);
    }
    return index;
  });
}

function parseCsv(csv, columns, optionsByKey) {
  const lines = splitLines(csv);
  const indices = parseCsvIndices(lines[0], columns);

  return lines.slice(1).map((line) => {
    const values = line.split(',');

    return indices.reduce((obj, valueIndex, index) => {
      if (valueIndex < 0) {
        return obj;
      }

      const { key, type } = columns[index];
      const value = values[valueIndex];

      if (type === 'text') {
        return {
          ...obj,
          [key]: value,
        };
      }

      const options = optionsByKey[key] ?? [];
      const found = options.find((option) => option.label.toLowerCase() === value.toLowerCase())
        ?? null;

      return {
        ...obj,
        [key]: found,
      };
    }, createConnection());
  });
}

function stripNonDigits(str) {
  return str.replace(/\D/g, '');
}

function getConnectData(selectedCompanyOption, connections) {
  const converted = connections.map((connection) => ({
    affiliate_id: connection.partner.value,
    api_client_id: stripNonDigits(connection.apiClientId),
    brand_id: connection.brand.value,
    integration_id: connection.integration.value,
    market_id: connection.market.value,
    user_id: connection.user.value,
    uuid: connection.uuid,
  }));

  const formData = new FormData();
  formData.append('company_id', selectedCompanyOption.value);
  formData.append('connections', JSON.stringify(converted));

  return formData;
}

function NewGoogleAdsAccount({
  brandOptions,
  clients,
  companyOptions,
  integrationOptions,
  marketOptions,
  multiBrand,
  multiMarket,
  partnerOptions,
  selectedCompanyOption,
  userOptions,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(selectedCompanyOption);
  const [connections, setConnections] = useState([createConnection()]);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsByKey = {
    integration: integrationOptions,
    brand: brandOptions,
    market: marketOptions,
    partner: partnerOptions,
    user: userOptions,
  };
  const canConnect = areValid(connections);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyOption?.value && lastCompanyId) {
      const found = companyOptions.find((option) => option.value === lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyOption, lastCompanyId]);

  const getWarning = (brandId, marketId) => {
    if (brandId && marketId) {
      if (brandId === multiBrand || marketId === multiMarket) return null;

      if (!clients[`${brandId}::${marketId}`]) {
        return 'No existing client with the selected brand and market. Adding this link will create a new client.';
      }
    }

    return null;
  };

  const checkClient = (connection) => {
    const { brand, market } = connection;

    return {
      ...connection,
      warning: getWarning(brand?.value, market?.value),
    };
  };

  const handleCsvUpload = async () => {
    const csv = await loadTextFile('.csv');
    const unaccented = removeAccents(csv);
    const parsed = parseCsv(unaccented, headers, optionsByKey);
    const checked = parsed.map((connection) => checkClient(connection));
    const existing = connections.filter((connection) => hasContent(connection));

    setConnections([...existing, ...checked]);
    addToast(`Parsed data for ${checked.length} connection(s)`);
  };

  const handleConnectionChange = (index, key, value) => {
    setConnections((last) => {
      const updated = {
        ...last[index],
        [key]: value,
      };
      const checked = checkClient(updated);
      return replaceAtIndex(last, index, checked);
    });
  };

  const handleConnect = async () => {
    setIsConnecting(true);
    const formData = getConnectData(selectedCompanyOption, connections);
    const { data, isError } = await post(internalGoogleAdsAccountsPath(), formData);

    if (isError) {
      addErrorToast('Could not connect Google Ads accounts. Please try again later');
    } else {
      const successes = data.results.filter(({ adAccountId }) => adAccountId);
      const failures = data.results.filter(({ adAccountId }) => !adAccountId);

      if (successes.length > 0) {
        addToast(`Connected ${successes.length} Google Ads accounts`);
      }
      if (failures.length > 0) {
        addErrorToast(`Could not connect ${failures.length} Google Ads accounts`);
      }

      setConnections((last) => {
        const error = 'This account could not be connected. '
          + 'Please verify the API Client ID and Integration Email';

        return last.reduce((arr, connection) => {
          const hasFailed = Boolean(failures.find(({ uuid }) => uuid === connection.uuid));

          if (hasFailed) {
            return [
              ...arr,
              {
                ...connection,
                error,
              },
            ];
          }

          return arr;
        }, []);
      });
    }
    setIsConnecting(false);
  };

  const handleDuplicate = () => {
    setConnections((arr) => {
      const duplicate = arr.at(-1) ?? createConnection();

      return [...arr, {
        ...duplicate,
        uuid: uuidv4(),
      }];
    });
  };

  return (
    <Card>
      <div className={styles.controls}>
        <Dropdown
          label="Company"
          menuProps={{ size: 'medium' }}
          options={companyOptions}
          selected={selectedCompany}
          size="medium"
          onChange={handleCompanyChange}
        />
        <div className="u-flexRow u-gap-16">
          <Button
            label="Download CSV Template"
            variant="tertiary"
            onClick={() => saveItemsCsvFile('googleAds', [], headers)}
          />
          <Button
            label="Upload CSV"
            variant="secondary"
            onClick={handleCsvUpload}
          />
        </div>
      </div>
      <BulkSelect
        headers={headers}
        optionsByKey={optionsByKey}
        rows={connections}
        onAdd={() => setConnections((last) => [...last, createConnection()])}
        onChange={handleConnectionChange}
        onDuplicate={handleDuplicate}
        onRemove={(index) => setConnections((last) => removeByIndex(last, index))}
      />
      <div className={styles.connect}>
        <div className="u-flexRow u-gap-8">
          <Button
            label="Reset"
            variant="secondary"
            onClick={() => setConnections([createConnection()])}
          />
          <ActionButton
            active={isConnecting}
            disabled={!canConnect}
            label="Connect"
            onClick={handleConnect}
          />
        </div>
      </div>
    </Card>
  );
}

NewGoogleAdsAccount.propTypes = propTypes;
NewGoogleAdsAccount.defaultProps = defaultProps;

export default NewGoogleAdsAccount;
