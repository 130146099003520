import React from 'react';
import PropTypes from 'prop-types';
import {
  NavFooter,
  NavHeader,
  NavMenu,
} from '@makeably/creativex-design-system';
import { arrayIf } from 'utilities/array';
import * as routes from 'utilities/routes';
import styles from './Navigation.module.css';

const featuresProps = PropTypes.shape({
  brand: PropTypes.bool.isRequired,
  customScores: PropTypes.bool.isRequired,
  diagnostics: PropTypes.bool.isRequired,
  editCompanyLogo: PropTypes.bool.isRequired,
  legacyScore: PropTypes.bool.isRequired,
  linkAccounts: PropTypes.bool.isRequired,
  manageTaxonomies: PropTypes.bool.isRequired,
  preflight: PropTypes.bool.isRequired,
  quality: PropTypes.bool.isRequired,
  regulatory: PropTypes.bool.isRequired,
  viewBenchmarksDashboard: PropTypes.bool.isRequired,
  viewBrand: PropTypes.bool.isRequired,
  viewCreativeLifecycle: PropTypes.bool.isRequired,
  viewDataPoints: PropTypes.bool.isRequired,
  viewInflightQuality: PropTypes.bool.isRequired,
  viewInflightRegulatory: PropTypes.bool.isRequired,
  viewMediaSpend: PropTypes.bool.isRequired,
  viewOverview: PropTypes.bool.isRequired,
  viewPartners: PropTypes.bool.isRequired,
  viewPreflightQuality: PropTypes.bool.isRequired,
  viewPreflightRegulatory: PropTypes.bool.isRequired,
  viewQuality: PropTypes.bool.isRequired,
  viewReports: PropTypes.bool.isRequired,
  viewRepresentation: PropTypes.bool.isRequired,
  viewTaxonomies: PropTypes.bool.isRequired,
});
export const navPropTypes = {
  companyName: PropTypes.string.isRequired,
  features: featuresProps.isRequired,
  fullName: PropTypes.string.isRequired,
  homeUrl: PropTypes.string.isRequired,
  isActingAs: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    brandId: PropTypes.string,
    channel: PropTypes.string,
    customFilterId: PropTypes.string,
    id: PropTypes.string,
    path: PropTypes.string,
    step: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  tierCollectionId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
};
const defaultProps = {
  logoUrl: undefined,
};

function getDashboard(features, ids) {
  const {
    viewBrand,
    viewInflightQuality,
    viewInflightRegulatory,
    viewPreflightQuality,
    viewPreflightRegulatory,
    viewRepresentation,
  } = features;
  const {
    brandId,
    id,
    scores,
  } = ids;

  return {
    icon: 'dashboard',
    label: 'Dashboards',
    options: [
      ...arrayIf(viewInflightQuality || viewPreflightQuality,
        ...scores.map((score) => ({
          label: score.label,
          urls: [
            ...arrayIf(viewInflightQuality,
              routes.auditReportsPath({ score: score.id })),
            ...arrayIf(viewPreflightQuality,
              routes.preflightReportsPath({ score: score.id })),
          ],
        }))),
      ...arrayIf(viewBrand,
        {
          label: 'Brand Consistency',
          urls: [
            routes.brandsPath(),
            routes.brandPath(brandId),
            routes.brandUsagePath(brandId),
            routes.brandBrandcuesPath(brandId),
            routes.brandBrandcuePath(brandId, id),
          ],
        }),
      ...arrayIf(viewInflightRegulatory || viewPreflightRegulatory,
        {
          label: 'Compliance',
          urls: [
            ...arrayIf(viewInflightRegulatory, routes.regulatoryOverviewIndexPath({ report_type: 'inflight' })),
            ...arrayIf(viewPreflightRegulatory, routes.regulatoryOverviewIndexPath({ report_type: 'preflight' })),
            routes.regulatoryOverviewIndexPath(),
          ],
        }),
      ...arrayIf(viewRepresentation,
        {
          label: 'Representation',
          urls: [routes.representationReportsPath()],
        }),
    ],
  };
}

function getReporting(features, ids) {
  const {
    diagnostics,
    viewBenchmarksDashboard,
    viewCreativeLifecycle,
    viewInflightQuality,
    viewPreflightQuality,
    viewReports,
    viewQuality,
  } = features;
  const { uuid } = ids;

  return {
    icon: 'reporting',
    label: 'Reporting',
    options: [
      ...arrayIf(viewReports || viewCreativeLifecycle,
        {
          label: 'New Report',
          urls: [
            routes.selectTypeReportingReportsPath(),
            routes.newReportingReportPath(),
          ],
        }),
      ...arrayIf(viewReports || viewCreativeLifecycle,
        {
          label: 'My Reports',
          urls: [
            routes.reportingReportsPath(),
            routes.editReportingReportPath(uuid),
          ],
        }),
      ...arrayIf(viewInflightQuality || viewPreflightQuality,
        {
          label: 'Rankings',
          urls: [
            ...arrayIf(viewInflightQuality, routes.auditRankingsPath()),
            ...arrayIf(viewPreflightQuality, routes.preflightRankingsPath()),
          ],
        }),
      ...arrayIf(viewBenchmarksDashboard,
        {
          label: 'Benchmarks',
          urls: [
            routes.benchmarksIndustryReportsPath(),
            routes.benchmarksCategoryReportsPath(),
          ],
        }),
      ...arrayIf(viewQuality,
        {
          label: 'Comparisons',
          urls: [routes.auditSegmentationsPath()],
        },
        {
          label: 'Trends',
          urls: [routes.auditHistoricalTrendsPath()],
        }),
      ...arrayIf(diagnostics,
        {
          label: 'Diagnostics',
          urls: [routes.auditDiagnosticsPath()],
        }),
      {
        label: 'Exports',
        urls: [routes.settingsCsvExportsPath()],
      },
    ],
  };
}

function getCreatives() {
  return {
    icon: 'creatives',
    label: 'Creatives',
    options: [
      {
        label: 'Creatives',
        urls: [routes.auditCreativesPath()],
      },
    ],
  };
}

function getPreflight(features) {
  const { preflight } = features;

  return {
    icon: 'preflight',
    label: 'Pre-Flight',
    options: [
      ...arrayIf(preflight,
        {
          label: 'Submit New',
          urls: [routes.newPreflightPretestPath()],
        },
        {
          label: 'View Submissions',
          urls: [routes.preflightPretestsPath()],
        }),
    ],
  };
}

function getConnections(features, ids) {
  const {
    linkAccounts,
    manageTaxonomies,
    viewTaxonomies,
  } = features;
  const {
    id,
    step,
  } = ids;

  return {
    icon: 'connections',
    label: 'Connections',
    options: [
      ...arrayIf(linkAccounts,
        {
          label: 'Data Coverage',
          urls: [routes.connectionsAdAccountsPath()],
        },
        {
          label: 'Ad Accounts',
          urls: [
            routes.settingsLinkedPlatformAccountsPath(),
            routes.editSettingsLinkedPlatformAccountPath(id),
            routes.reconnectLapsedAccountsAccountSetupMetaAdsPath(),
            routes.relinkInstructionsAccountSetupDv360AdPath(id),
            routes.relinkInstructionsAccountSetupInstagramPostPath(id),
            routes.relinkInstructionsAccountSetupSnapchatAdPath(id),
          ],
        },
        {
          label: 'Brand Pages',
          urls: [
            routes.settingsFacebookAdPagesPath(),
            routes.editAccountSetupFacebookAdPagePath(id),
            routes.reconnectLapsedPagesAccountSetupFacebookBrandPagesPath(),
          ],
        },
        {
          label: 'Link New Account',
          urls: [
            routes.newSettingsLinkedPlatformAccountPath(),
            routes.accountSetupAdformAdPath(step),
            routes.accountSetupAmazonAdPath(step),
            routes.accountSetupDv360AdsPath(),
            routes.accountSetupFacebookPostsPath(),
            routes.accountSetupInstagramPostsPath(),
            routes.accountSetupMetaAdPath(step),
            routes.accountSetupSnapchatAdsPath(),
            routes.accountSetupTiktokAdPath(step),
            routes.accountSetupTwitterAdsPath(),
            routes.accountSetupPinterestIndexPath(),
            routes.confirmNewAccountsAccountSetupDv360AdsPath(),
            routes.newAccountSetupFacebookPostPath(),
            routes.newAccountSetupInstagramPostPath(),
            routes.newAccountSetupSnapchatAdPath(),
            routes.newAccountSetupTwitterAdPath(),
            routes.newAccountSetupTwitterPostPath(),
            routes.newAccountSetupYoutubePostPath(),
            routes.selectHandlesAccountSetupTwitterAdsPath(),
            routes.selectPartnerAccountSetupDv360AdsPath(),
            routes.setupInstructionsAccountSetupDv360AdsPath(),
            routes.setupInstructionsAccountSetupInstagramPostsPath(),
          ],
        }),
      ...arrayIf(viewTaxonomies,
        {
          label: 'Taxonomy',
          urls: [
            ...arrayIf(manageTaxonomies,
              routes.taxonomyCompanyTaxonomiesPath(),
              routes.taxonomyCompanyBrandCodesPath(),
              routes.taxonomyCompanyMarketCodesPath()),
            routes.taxonomyUnparsableCampaignsPath(),
            routes.taxonomyManuallyMappedCampaignsPath(),
          ],
        }),
    ],
  };
}

function getReference(features, ids) {
  const {
    brand,
    customScores,
    legacyScore,
    quality,
    regulatory,
  } = features;
  const {
    id,
    tierCollectionId,
  } = ids;

  return {
    icon: 'guidelines',
    label: 'Reference',
    options: [
      ...arrayIf(quality,
        {
          label: 'Guidelines',
          urls: [routes.guidelinesPath()],
        }),
      ...arrayIf(brand,
        {
          label: 'Brand Cues',
          urls: [routes.deprecatedIndexGuidelinesPath({ rule_type: 'category' })],
        }),
      ...arrayIf(regulatory,
        {
          label: 'Regulations',
          urls: [routes.deprecatedIndexGuidelinesPath({ rule_type: 'regulatory' })],
        }),
      ...arrayIf(quality,
        {
          label: 'Scores',
          urls: [
            routes.scoresPath(),
            routes.scorePath(id),
          ],
        }),
      ...arrayIf(customScores || legacyScore || brand,
        {
          label: 'Scoring Tiers',
          urls: [
            ...arrayIf(customScores || legacyScore, routes.tierPath(tierCollectionId)),
            ...arrayIf(brand, routes.editScoringConsistencyPath()),
          ],
        }),
    ],
  };
}

function getCreativeLifecycle(features, ids) {
  const { id } = ids;
  const { viewCreativeLifecycle } = features;

  return {
    icon: 'productionEfficiency',
    label: 'Creative Lifecycle',
    options: [
      ...arrayIf(viewCreativeLifecycle,
        {
          label: 'Dashboard',
          urls: [routes.creativeLifecycleDashboardIndexPath()],
        },
        {
          label: 'Upload Core Assets',
          urls: [routes.newCreativeLifecycleCoreAssetUploadPath()],
        },
        {
          label: 'View Campaigns',
          urls: [
            routes.creativeLifecycleCampaignsPath(),
            routes.editCreativeLifecycleCampaignPath(id),
            routes.creativeLifecycleCoreAssetPath(id),
          ],
        }),
    ],
  };
}

function getCompany(features, ids) {
  const {
    viewDataPoints,
    viewMediaSpend,
    viewOverview,
    viewPartners,
  } = features;
  const {
    id,
    userId,
  } = ids;
  const isSelf = id === userId;

  return {
    icon: 'company',
    label: 'Company',
    options: [
      ...arrayIf(viewDataPoints,
        {
          label: 'Data Point Usage',
          urls: [routes.contractDataPointUsagePath()],
        }),
      ...arrayIf(viewMediaSpend,
        {
          label: 'Media Spend Usage',
          urls: [routes.contractMediaSpendUsagePath()],
        }),
      ...arrayIf(viewOverview,
        {
          label: 'Overview',
          urls: [routes.settingsCompanyOnboardingPath()],
        }),
      {
        label: 'Users',
        urls: [
          routes.settingsUsersPath(),
          routes.editSettingsUserPermissionPath(id),
          // @note: Don't highlight Settings/Users when you are editing yourself
          ...arrayIf(!isSelf, routes.editSettingsUserPath(id)),
          routes.newSettingsUserPath(),
          routes.newSettingsBulkInvitePath(),
        ],
      },
      ...arrayIf(viewPartners,
        {
          label: 'Partners',
          urls: [
            routes.settingsPartnersPath(),
            routes.newLinkSettingsPartnersPath(),
          ],
        }),
    ],
  };
}

function getSettings(features, ids) {
  const { editCompanyLogo } = features;
  const {
    customFilterId,
    userId,
  } = ids;

  return {
    icon: 'settings',
    label: 'Settings',
    options: [
      {
        label: 'My Profile',
        urls: [
          routes.editSettingsUserPath(userId),
          routes.preferencesSettingsUserPath(userId),
        ],
      },
      {
        label: 'Company Details',
        urls: [
          routes.settingsCustomFiltersPath(),
          routes.settingsCustomFilterOptionsPath(customFilterId),
          ...arrayIf(editCompanyLogo, routes.editSettingsCompanyLogoPath()),
        ],
      },
    ],
  };
}

function getMenu(features, ids) {
  const all = [
    getDashboard(features, ids),
    getReporting(features, ids),
    getCreatives(),
    getPreflight(features),
    getConnections(features, ids),
    getReference(features, ids),
    getCreativeLifecycle(features, ids),
    getCompany(features, ids),
    getSettings(features, ids),
  ];

  return all.filter((section) => section !== null);
}

function getUserOptions(isAdmin, isActingAs) {
  const backToInternal = {
    label: 'Back to Internal',
    url: routes.backToInternalInternalUsersPath(),
  };
  const internal = {
    label: 'Internal',
    url: routes.adminsInternalUsersPath(),
  };

  return [
    ...(isAdmin ? [internal] : []),
    ...(isActingAs ? [backToInternal] : []),
    {
      label: 'Help Center',
      newTab: true,
      url: routes.helpCenterPath(),
    },
    {
      label: 'Sign Out',
      url: routes.logoutPath(),
    },
  ];
}

function Navigation({
  companyName,
  features,
  fullName,
  homeUrl,
  isActingAs,
  isAdmin,
  logoUrl,
  params,
  scores,
  tierCollectionId,
  userId,
}) {
  const ids = {
    brandId: params.brandId ?? params.id ?? '0',
    channel: params.channel ?? 'null',
    customFilterId: params.customFilterId ?? '0',
    id: params.id ?? '0',
    scores,
    step: params.step ?? 'null',
    tierCollectionId: tierCollectionId ?? '0',
    uuid: params.uuid ?? '0',
    userId,
  };

  const menu = getMenu(features, ids);
  const user = {
    companyName,
    fullName,
    logoUrl,
  };
  const userOptions = getUserOptions(isAdmin, isActingAs);

  return (
    <div className={`nav ${styles.main}`}>
      <div className={styles.header}>
        <NavHeader homeUrl={homeUrl} />
      </div>
      <div className={styles.body}>
        <NavMenu
          currentParams={window.location.search}
          currentUrl={window.location.pathname}
          menu={menu}
        />
      </div>
      <div className={styles.footer}>
        <NavFooter
          options={userOptions}
          user={user}
        />
      </div>
    </div>
  );
}

Navigation.propTypes = navPropTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;
