import React from 'react';
import {
  Button,
  CloseButton,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import ScoreDetailInputs from 'components/scores/modal/ScoreDetailInputs';
import { getAuthenticityToken } from 'utilities/requests';
import { scorePath } from 'utilities/routes';
import styles from '../Steps.module.css';

async function onSubmit({
  description,
  label,
  name,
  qualityRate,
  qualitySpendRate,
  scoreId,
  setIsNextDisabled,
  showInDashboard,
  showInReporting,
  showInScorecard,
}) {
  setIsNextDisabled(true);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('description', description);
  formData.append('label', label);
  formData.append('name', name);
  formData.append('quality_rate_name', qualityRate);
  formData.append('quality_spend_rate_name', qualitySpendRate);
  formData.append('show_in_dashboard', showInDashboard);
  formData.append('show_in_reporting', showInReporting);
  formData.append('show_in_scorecard', showInScorecard);

  const response = await fetch(scorePath(scoreId), {
    method: 'PATCH',
    body: formData,
  });

  if (response.ok) {
    window.location.href = scorePath(scoreId);
  } else {
    addToast('Something went wrong, please try again.', {
      size: 'large',
      type: 'error',
    });
  }
}

function getContent(step, props) {
  const {
    canEditVisibility,
    description,
    initialDescription,
    initialLabel,
    initialName,
    initialQualityRate,
    initialQualitySpendRate,
    initialShowInDashboard,
    initialShowInReporting,
    initialShowInScorecard,
    isDefault,
    isStandard,
    label,
    name,
    qualityRate,
    qualitySpendRate,
    scoreDenyList,
    setDescription,
    setIsNextDisabled,
    setLabel,
    setName,
    setQualityRate,
    setQualitySpendRate,
    setShowInDashboard,
    setShowInReporting,
    setShowInScorecard,
    showInDashboard,
    showInReporting,
    showInScorecard,
  } = props;

  const initialScoreValues = {
    description: initialDescription,
    label: initialLabel,
    name: initialName,
    qualityRate: initialQualityRate,
    qualitySpendRate: initialQualitySpendRate,
    showInDashboard: initialShowInDashboard,
    showInReporting: initialShowInReporting,
    showInScorecard: initialShowInScorecard,
  };

  switch (step) {
    case 1:
      return (
        <ScoreDetailInputs
          canEditVisibility={canEditVisibility}
          description={description}
          initialScoreValues={initialScoreValues}
          isDefault={isDefault}
          isStandard={isStandard}
          label={label}
          name={name}
          qualityRate={qualityRate}
          qualitySpendRate={qualitySpendRate}
          scoreDenyList={scoreDenyList}
          setIsNextDisabled={setIsNextDisabled}
          showInDashboard={showInDashboard}
          showInReporting={showInReporting}
          showInScorecard={showInScorecard}
          isEditing
          onDescriptionChange={setDescription}
          onLabelChange={setLabel}
          onNameChange={setName}
          onQualityRateChange={setQualityRate}
          onQualitySpendRateChange={setQualitySpendRate}
          onShowInDashboardChange={setShowInDashboard}
          onShowInReportingChange={setShowInReporting}
          onShowInScorecardChange={setShowInScorecard}
        />
      );
    case 2:
      return (
        <>
          <h5 className="u-marginBottom-16">{ label }</h5>
          <div className="u-marginBottom-8">{ description }</div>
          { initialShowInDashboard !== showInDashboard && (
            <div>{ `This score will ${showInDashboard ? 'show' : 'be hidden'} in Dashboards.` }</div>
          ) }
          { initialShowInReporting !== showInReporting && (
            <div>{ `This score will ${showInReporting ? 'show in' : 'be hidden from'} the Creatives page and all reports, including exports and API integrations.` }</div>
          ) }
          { initialShowInScorecard !== showInScorecard && (
            <div>{ `This score will ${showInScorecard ? 'show' : 'be hidden'} in the Pre-Flight Scorecard PDF.` }</div>
          ) }
          { initialName !== name && <div>{ `Custom Score Name: ${name}` }</div> }
          { initialQualityRate !== qualityRate && <div>{ `Custom Quality Rate: ${qualityRate}` }</div> }
          { initialQualitySpendRate !== qualitySpendRate && (
            <div>{ `Custom Quality Spend Rate: ${qualitySpendRate}` }</div>
          ) }
        </>
      );
    default:
      return null;
  }
}

function getFooter(step, props) {
  const {
    isNextDisabled,
    onClose,
    onNext,
    onPrevious,
  } = props;

  switch (step) {
    case 1: {
      return (
        <div className="buttonGroup">
          <Button
            label="Cancel"
            variant="tertiary"
            onClick={onClose}
          />
          <Button
            disabled={isNextDisabled}
            label="Update"
            onClick={onNext}
          />
        </div>
      );
    }
    case 2:
      return (
        <div className="buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            disabled={isNextDisabled}
            label="Confirm"
            onClick={() => onSubmit(props)}
          />
        </div>
      );
    default:
      return null;
  }
}

function getHeader(step, props) {
  const { onClose } = props;

  switch (step) {
    case 1:
      return (
        <div className={styles.header}>
          <h4>Edit Score Details</h4>
          <CloseButton onClick={onClose} />
        </div>
      );
    case 2:
      return (
        <div className={styles.header}>
          <h4>Confirm New Score Details</h4>
          <CloseButton onClick={onClose} />
        </div>
      );
    default:
      return null;
  }
}

function getModalSize(step) {
  if (step === 2) {
    return 'medium';
  }

  return 'custom';
}

export function getStepContent(step, props) {
  return {
    content: getContent(step, props),
    footer: getFooter(step, props),
    header: getHeader(step, props),
    size: getModalSize(step),
  };
}
