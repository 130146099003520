import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  BasicModal,
  Button,
  Card,
  MoreButton,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { getStepContent } from 'components/scores/index/Steps';
import ScoreModal, { guidelineProps } from 'components/scores/modal/ScoreModal';
import ScoreDetails, { scoreProps } from 'components/scores/ScoreDetails';
import { track } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import {
  scorePath,
  scoresPath,
  updateDefaultScorePath,
} from 'utilities/routes';
import styles from './Scores.module.css';

const countProps = {
  count: PropTypes.number,
  id: PropTypes.number,
  state: PropTypes.string,
};

const propTypes = {
  canCreateScore: PropTypes.bool.isRequired,
  canEditVisibility: PropTypes.bool.isRequired,
  canModifyScoreDetails: PropTypes.bool.isRequired,
  counts: PropTypes.arrayOf(PropTypes.shape(countProps)).isRequired,
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ).isRequired,
  isCxAdmin: PropTypes.bool.isRequired,
  scoreDenyList: PropTypes.arrayOf(PropTypes.string).isRequired,
  scores: PropTypes.arrayOf(PropTypes.shape(scoreProps)).isRequired,
};

function showError() {
  addToast('Error: Something went wrong. Please try again.', {
    size: 'large',
    type: 'error',
  });
}

function generateCountMap(countsArray) {
  // create map from counts array to allow count lookup by score version id and job status
  // e.g. countMap.get(svId).pending, countMap.get(svId).total, etc
  return countsArray.reduce((countMap, jobCount) => {
    const {
      count,
      id,
      state,
    } = jobCount;

    const stateCount = countMap.get(id) ?? { total: 0 };

    return countMap.set(id, {
      ...stateCount,
      [state]: count,
      total: stateCount.total + count,
    });
  }, new Map());
}

async function updateScore(url, method) {
  const response = await fetch(url, {
    method,
    headers: { 'X-CSRF-Token': getAuthenticityToken() },
  });

  if (response.ok) {
    return response.json();
  }

  showError();
  return false;
}

async function deactivate(id, label) {
  const success = await updateScore(scorePath(id), 'DELETE');

  if (success) {
    track('deactivate_score', {
      score_id: id,
      score_label: label,
    });
    window.location.href = scoresPath();
  } else {
    showError();
  }

  return false;
}

async function setDefault(id, label) {
  const success = await updateScore(updateDefaultScorePath(id), 'POST');

  if (success) {
    track('new_default_score', {
      score_id: id,
      score_label: label,
    });
    window.location.href = scoresPath();
  } else {
    showError();
  }

  return false;
}

function onAction(score, setContent, showModal, type) {
  const { id, label } = score;

  if (type === 'delete') {
    setContent({
      footerButtonProps: {
        label: 'Delete',
        onClick: () => deactivate(id, label),
        variant: 'destructive',
      },
      headerCopy: `Are you sure you want to delete ${label}?`,
    });
  } else {
    setContent({
      footerButtonProps: {
        label: 'Confirm',
        onClick: () => setDefault(id, label),
      },
      headerCopy: `Are you sure you want to set ${label} as default?`,
    });
  }

  showModal();
}

function renderScoreCard(score, canModify, isCxAdmin, setContent, showModal, stateCounts) {
  const {
    isDefault,
    isStandard,
    status,
    scoreType,
  } = score;

  const options = [];

  if (status !== 'processing' && canModify && !isDefault && !(isStandard && !isCxAdmin)) {
    const isDeletable = !isStandard && (isCxAdmin || scoreType === 'CustomScore');

    if (isDeletable) {
      options.push({
        label: 'Delete',
        onClick: () => onAction(score, setContent, showModal, 'delete'),
      });
    }

    options.push({
      label: 'Set as Default',
      onClick: () => onAction(score, setContent, showModal, 'default'),
    });
  }

  const moreButton = options.length > 0 && (
    <MoreButton options={options} />
  );

  return (
    <Card key={score.id}>
      <ScoreDetails
        {...score}
        stateCounts={stateCounts}
        titleContent={moreButton}
        showDetailsButton
        showDivider
      />
    </Card>
  );
}

function Scores({
  canCreateScore,
  canEditVisibility,
  canModifyScoreDetails: canModify,
  counts,
  guidelines,
  isCxAdmin,
  scoreDenyList,
  scores,
}) {
  const [modalContent, setModalContent] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNewScoreModal, setShowNewScoreModal] = useState(false);
  const countMap = generateCountMap(counts);

  const showModal = () => {
    setShowConfirmationModal(true);
  };

  const { footerButtonProps, headerCopy } = modalContent;
  const footer = (
    <div className="buttonGroup">
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={() => setShowConfirmationModal(false)}
      />
      <Button {...footerButtonProps} />
    </div>
  );
  const header = (
    <div className={styles.header}>
      <h5>{ headerCopy }</h5>
    </div>
  );

  return (
    <>
      { canCreateScore && (
        <div className={styles.addNewRow}>
          <AddNewButton
            label="New Score"
            onClick={() => setShowNewScoreModal(true)}
          />
        </div>
      ) }
      <div className={styles.cards}>
        { scores.map((score) => (
          renderScoreCard(
            score,
            canModify,
            isCxAdmin,
            setModalContent,
            showModal,
            countMap.get(score.versionid),
          )
        )) }
      </div>
      <ScoreModal
        canEditVisibility={canEditVisibility}
        defaultWeight={1}
        getStepContent={getStepContent}
        guidelines={guidelines}
        isOpen={showNewScoreModal}
        scoreDenyList={scoreDenyList}
        onClose={() => setShowNewScoreModal(false)}
      />
      <BasicModal
        footer={footer}
        header={header}
        isOpen={showConfirmationModal}
        size="medium"
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
}

Scores.propTypes = propTypes;

export default Scores;
