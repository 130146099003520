import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';

export const NULL_VALUE = '~';

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

export const optionArrayProps = PropTypes.arrayOf(optionProps);

export const filterProps = PropTypes.objectOf(
  PropTypes.arrayOf(optionProps),
);

export const emptyState = (
  <div className="t-empty u-flexRow u-justifyCenter u-marginTop-16">
    No data to display
  </div>
);

export function getNullableDimension(value) {
  if (value === null || typeof value === 'undefined') {
    return {
      label: 'N/A',
      value: NULL_VALUE,
    };
  }

  return { value };
}

export function renderBetaTag() {
  return (
    <Tooltip label="If this feature has been useful to you, or if you have new use cases, please let us know in the #project-internal-tools slack channel">
      <Tag color="blue" label="Beta" />
    </Tooltip>
  );
}

export function renderDeleteButton(onClick) {
  return (
    <Button
      label="Delete"
      variant="destructive"
      onClick={onClick}
    />
  );
}
