import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  findObjectByValue,
  removeObjectByValue,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import {
  getBinsByTag,
  getBinItems,
} from 'components/reporting/binItems';
import BreakdownDrawer from 'components/reporting/BreakdownDrawer';
import ConfigureReport from 'components/reporting/ConfigureReport';
import MetricVisualization from 'components/reporting/MetricVisualization';
import ReportDatePicker from 'components/reporting/ReportDatePicker';
import ReportingFilter from 'components/reporting/ReportingFilter';
import ReportTags from 'components/reporting/ReportTags';
import {
  customFilterProps,
  dateOptionProps,
  guidelineScoreProps,
  guidelinesDetailsProps,
  initialDateRange,
  mixpanelDateChange,
  propertiesProps,
  scoreProps,
} from 'components/reporting/shared';
import {
  calcPropertiesJson,
  getHeaders,
  getMetrics,
  getGuidelineMetrics,
  getGuidelinesSegments,
  parseProperties,
  preprocessRecords,
} from 'components/reporting/utilities';
import { saveItemsCsvFile } from 'utilities/file';
import { getObjFilterTest } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { removeProperty } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import {
  guidelineRecordsReportingReportsPath,
  editReportingReportPath,
} from 'utilities/routes';
import styles from './GuidelineReport.module.css';

const propTypes = {
  canViewSpend: PropTypes.bool.isRequired,
  customFilters: PropTypes.arrayOf(customFilterProps).isRequired,
  customRangeProps: PropTypes.shape({
    customDatesEnabled: PropTypes.bool,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  dateOptions: PropTypes.arrayOf(dateOptionProps).isRequired,
  guidelineScores: PropTypes.arrayOf(guidelineScoreProps).isRequired,
  guidelinesDetails: PropTypes.arrayOf(guidelinesDetailsProps).isRequired,
  initialDescription: PropTypes.string.isRequired,
  initialProperties: propertiesProps.isRequired,
  initialTitle: PropTypes.string.isRequired,
  scores: PropTypes.arrayOf(scoreProps).isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

const defaultProps = {
  uuid: undefined,
};

async function getData(type, dateOption) {
  try {
    const params = {
      end_date: dateOption.endDate,
      start_date: dateOption.startDate,
      date_type: dateOption.type,
      type,
    };
    const headers = { 'X-CSRF-Token': getAuthenticityToken() };

    const response = await fetch(guidelineRecordsReportingReportsPath(params), { headers });
    if (!response.ok) {
      return { error: response.status };
    }
    return { data: await response.json() };
  } catch {
    return { error: 500 };
  }
}

function updateVizMetric(metric, selected) {
  if (metric && findObjectByValue(selected, metric)) {
    return metric;
  }
  return selected[0];
}

function addScoresToRecords(rawRecords, guidelineScores) {
  return rawRecords.map((record) => {
    const newRecord = { ...record };
    guidelineScores.forEach((score) => {
      if (score.guidelineNames.includes(newRecord.guideline)) {
        if (newRecord.scoreName) {
          newRecord.scoreName.push(score.name);
        } else {
          newRecord.scoreName = [score.name];
        }
      }
    });
    return newRecord;
  });
}

function addGuidelineScores(guidelineScores, selectedFilters, items) {
  const newItems = [];
  // The user can filter by score, but if no filters are selected, then the
  // filters array has to include all score names.
  let filters = guidelineScores.map((score) => score.name);
  if (selectedFilters.scoreName) {
    filters = selectedFilters.scoreName.map((score) => score.value);
  }
  // For each score that has not been filtered...
  guidelineScores.filter((score) => filters.includes(score.name)).forEach((score) => {
    // For each row in the table (each guideline)...
    items.forEach((item) => {
      // Add a new row (guideline) with the same metrics, but the respective score.
      if (score.guidelineNames.includes(item.guideline.value)) {
        const newItem = { ...item };
        newItem.scoreName = {
          label: score.name,
          value: score.name,
        };
        newItem.id = {
          value: `${newItem.guideline.value}::${score.name}`,
        };
        newItems.push(newItem);
      }
    });
  });
  return newItems;
}

const recordGlobalMarketCheck = (record) => record.markets.includes('All') || record.markets.includes('Central');

const addGuidelineDetailsToRecords = (guidelinesDetails, preprocessedRecords) => {
  const guidelineDetailsMap = new Map();
  guidelinesDetails.forEach((detail) => {
    if (guidelineDetailsMap.has(detail.name)) {
      guidelineDetailsMap.get(detail.name).push(...detail.markets);
    } else {
      guidelineDetailsMap.set(detail.name, detail.markets);
    }
  });
  return preprocessedRecords.map((record) => {
    const newRecord = { ...record };

    if (guidelineDetailsMap.has(record.guideline)) {
      newRecord.markets = guidelineDetailsMap.get(record.guideline);
    } else {
      newRecord.markets = ['All'];
    }
    return newRecord;
  });
};

function GuidelineReport({
  canViewSpend,
  customFilters,
  customRangeProps,
  dateOptions,
  initialDescription,
  initialProperties,
  initialTitle,
  type,
  uuid,
  scores,
  guidelineScores,
  guidelinesDetails,
}) {
  const [calculating, setCalculating] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [items, setItems] = useState([]);
  // The final items for display (rows) with the additional score copies
  const [itemsWithAdditionalRows, setItemsWithAdditionalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [page, setPage] = useState(1);
  const [rawRecords, setRawRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedDateOption, setSelectedDateOption] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange());
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState(
    segments.filter((segment) => segment.disabled),
  );
  const [sort, setSort] = useState();
  const [sortedItems, setSortedItems] = useState([]);
  const [useCustomDates, setUseCustomDates] = useState(false);
  const [vizMetric, setVizMetric] = useState(updateVizMetric(undefined, selectedMetrics));
  const filterCount = Object.keys(selectedFilters).length;
  const segmentsWithoutGroups = segments.filter(({ group }) => !group);

  const propertiesJson = calcPropertiesJson({
    selectedDateOption: useCustomDates ? selectedDateRange : selectedDateOption,
    selectedFilters,
    selectedMetrics,
    selectedSegments,
    sort,
    vizMetric,
  });
  const hasChanged = propertiesJson !== JSON.stringify(initialProperties);

  useEffect(() => {
    setSegments(getGuidelinesSegments(scores, customFilters));
    setMetrics(getMetrics(canViewSpend).concat(getGuidelineMetrics()));
  }, [scores, customFilters]);

  useEffect(() => {
    if (metrics.length > 0) {
      const parsed = parseProperties(initialProperties, {
        customRangeProps,
        dateOptions,
        metrics,
        segments,
      });

      setSelectedDateOption(parsed.selectedDateOption);
      setSelectedDateRange(parsed.selectedDateRange);
      setUseCustomDates(parsed.useCustomDates);
      setSelectedFilters(parsed.selectedFilters);
      setSelectedMetrics(parsed.selectedMetrics);
      // If the selectedSegments don't include the required segments, we need to add them here.
      // We also check to make sure they do not already exist, and add them twice (as is the case
      // when a user is editing a report)
      const segmentsWithRequired = parsed.selectedSegments;
      segments.filter((segment) => segment.disabled).forEach((requiredSegment) => {
        if (!segmentsWithRequired.some((segment) => segment.value === requiredSegment.value)) {
          segmentsWithRequired.push(requiredSegment);
        }
      });
      setSelectedSegments(segmentsWithRequired);
      setSort(parsed.sort);
      setVizMetric(parsed.vizMetric);
    }
  }, [initialProperties, dateOptions, segments, metrics]);

  useEffect(() => {
    (async () => {
      if (useCustomDates) mixpanelDateChange(selectedDateRange, 'guideline');

      const dateOption = useCustomDates ? selectedDateRange : selectedDateOption;
      if (dateOption) {
        setLoading(true);
        const responses = await Promise.all([
          getData('inflight', dateOption),
          getData('preflight', dateOption),
        ]);

        if (responses.some((resp) => resp.error)) {
          const hasTimeout = responses.some((resp) => resp.error === 504);
          const message = hasTimeout ? 'The data request has timed out' : 'The data could not be loaded';

          addToast(message, { type: 'error' });
          setRawRecords([]);
        } else {
          setRawRecords(responses.reduce((all, resp) => [...all, ...resp.data], []));
        }
        setLoading(false);
      }
    })();
  }, [selectedDateOption, selectedDateRange]);

  useEffect(() => {
    const recordsWithScores = addScoresToRecords(rawRecords, guidelineScores);
    let preprocessedRecords = preprocessRecords(recordsWithScores, scores, customFilters);
    // Add the guideline details separately
    preprocessedRecords = addGuidelineDetailsToRecords(guidelinesDetails, preprocessedRecords);
    setRecords(preprocessedRecords);
  }, [rawRecords, scores, customFilters]);

  useEffect(() => {
    // Filter the records by market client-side
    let marketFilteredRecords = records;
    if (selectedFilters.market) {
      marketFilteredRecords = marketFilteredRecords.filter(
        (record) => recordGlobalMarketCheck(record)
         || record.markets.some(
           (market) => selectedFilters.market.some((filterMarket) => filterMarket.label === market),
         ),
      );
    } else {
      marketFilteredRecords = marketFilteredRecords.filter(
        (record) => recordGlobalMarketCheck(record),
      );
    }

    const filterTest = getObjFilterTest(selectedFilters);

    setFilteredRecords(marketFilteredRecords.filter(filterTest));
    setPage(1);
  }, [records, selectedFilters]);

  useEffect(() => {
    setCalculating(true);
    // @note: timeout lets component render with calculating true before calculation
    const timerId = setTimeout(() => {
      const bins = getBinsByTag(selectedSegments, filteredRecords);
      const allItems = getBinItems(bins, selectedSegments, scores);
      setCalculating(false);

      setPage(1);
      setHeaders(getHeaders(selectedSegments, selectedMetrics, vizMetric));
      setItems(allItems);
    }, 10);

    return () => clearTimeout(timerId);
  }, [filteredRecords, selectedSegments, selectedMetrics, vizMetric, scores]);

  useEffect(() => {
    // Some segments need to be added at the very end as individual rows,
    // and cannot be included in the binning, as to not affect the overall metric totals.
    let newItems = [];
    if (selectedSegments.some((segment) => segment.value === 'scoreName')) {
      newItems = addGuidelineScores(guidelineScores, selectedFilters, items);
    } else {
      newItems = [...items];
    }
    setItemsWithAdditionalRows(newItems);
  }, [items, selectedFilters]);

  useEffect(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      const sorted = itemsWithAdditionalRows.slice().sort(byKeyDir);
      const indexed = sorted.map((item, index) => ({
        ...item,
        index: { value: index + 1 },
      }));

      setSortedItems(indexed);
    }
  }, [itemsWithAdditionalRows, sort]);

  const updateSelectedMetrics = (selected) => {
    setVizMetric((metric) => updateVizMetric(metric, selected));
    setSelectedMetrics(selected);
  };

  const removeSelectedMetric = (option) => {
    updateSelectedMetrics(removeObjectByValue(selectedMetrics, option));
  };

  const removeSelectedSegment = (option) => {
    setSelectedSegments((last) => removeObjectByValue(last, option));
  };

  const removeSelectedFilter = (key) => {
    setSelectedFilters((last) => removeProperty(last, key));
  };

  const handleSave = (reportUuid) => {
    window.location.href = editReportingReportPath(reportUuid);
  };

  const handleDateRangeChange = (date, dateLabel) => {
    setSelectedDateRange((prev) => (
      {
        ...prev,
        [dateLabel]: date,
      }
    ));
  };

  const getEmptyStateMessage = () => {
    if (loading) {
      return '';
    }
    if (calculating) {
      return 'Calculating metrics';
    }
    if (records.length === 0) {
      return 'No data to display';
    }
    if (sortedItems.length === 0 && filterCount !== 0) {
      return 'Remove filters to see data';
    }
    return null;
  };

  const renderTable = () => {
    const message = getEmptyStateMessage();

    if (message !== null) {
      return (
        <div className={`t-empty ${styles.empty}`}>
          { message }
        </div>
      );
    }

    return (
      <ItemsTable
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={(value) => setPage(value)}
        onSortChange={setSort}
      />
    );
  };

  return (
    <>
      <ConfigureReport
        hasChanged={hasChanged}
        initialDescription={initialDescription}
        initialTitle={initialTitle}
        propertiesJson={propertiesJson}
        type={type}
        uuid={uuid}
        onExportCsv={(title) => saveItemsCsvFile(title, sortedItems, headers)}
        onSave={handleSave}
      />
      <Card padding={false}>
        <div className={styles.top}>
          <div className={styles.controls}>
            <div className={styles.controlButtons}>
              <Button
                label="Setup"
                variant="secondary"
                onClick={() => setDrawerOpen(true)}
              />
              <ReportingFilter
                isOpen={filterOpen}
                records={records}
                segments={segmentsWithoutGroups}
                selections={selectedFilters}
                onClose={() => setFilterOpen(false)}
                onOpen={() => setFilterOpen(true)}
                onSelectionsChange={setSelectedFilters}
              />
            </div>
            <ReportDatePicker
              customRangeProps={customRangeProps}
              dateOptions={dateOptions}
              handleDateChange={setSelectedDateOption}
              handleDateRangeChange={handleDateRangeChange}
              loading={loading}
              selectedDateOption={selectedDateOption}
              selectedDateRange={selectedDateRange}
              setUseCustomDates={setUseCustomDates}
              useCustomDates={useCustomDates}
            />
          </div>
          <ReportTags
            filters={selectedFilters}
            removeFilter={removeSelectedFilter}
            removeSelectedMetric={removeSelectedMetric}
            removeSelectedSegment={removeSelectedSegment}
            segments={segmentsWithoutGroups}
            selectedMetrics={selectedMetrics}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            onFilterClick={() => setFilterOpen(true)}
            onMetricClick={() => setDrawerOpen(true)}
            onSegmentClick={() => setDrawerOpen(true)}
          />
        </div>
        <Divider />
        <MetricVisualization
          displayMetric={vizMetric}
          items={sortedItems}
          loading={loading || calculating}
          selectedMetrics={selectedMetrics}
          selectedSegments={selectedSegments}
          onDisplayMetricChange={setVizMetric}
        />
        <Divider />
        <div className={styles.table}>
          { renderTable() }
        </div>
      </Card>
      <BreakdownDrawer
        isOpen={drawerOpen}
        metrics={metrics}
        segments={segments}
        selectedMetrics={selectedMetrics}
        selectedSegments={selectedSegments}
        setSelectedMetrics={updateSelectedMetrics}
        setSelectedSegments={setSelectedSegments}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}

GuidelineReport.propTypes = propTypes;
GuidelineReport.defaultProps = defaultProps;

export default GuidelineReport;
